import React from 'react';
import { graphql } from 'gatsby';
import imageUrlFor from '@helpers/imageUrlFor';
import PropTypes from 'prop-types';
import BlogListing from '@components/BlogListing';
import CategoryNavigation from '@components/CategoryNavigation';
import ImageTextOverlap from '@components/ImageTextOverlap';
import Pagination from '@components/Pagination';
import useBlogCategories from '@utility/useBlogCategories';
import values from 'lodash.values';
import mapValues from 'lodash.mapvalues';
import SEO from '@components/seo';

export const blogPostsFragment = graphql`
  fragment blogPostsFragment on SanityBlogPost {
    _id
    title
    description
    category {
      title
    }
    slug {
      current
    }
    author
    date(formatString: "MM/DD/YYYY")
    image {
      asset {
        _id
      }
    }
  }
`;

export const query = graphql`
  query(
    $skip: Int!
    $limit: Int!
    $featuredPrimaryId: String!
    $featuredSecondaryId: String!
  ) {
    allSanityBlogPost(
      skip: $skip
      limit: $limit
      sort: { fields: date, order: DESC }
      filter: { _id: { nin: [$featuredPrimaryId, $featuredSecondaryId] } }
    ) {
      edges {
        node {
          ...blogPostsFragment
        }
      }
    }
    allSanityBlogPage {
      edges {
        node {
          _id
          title
          _rawFeaturedBlogPostPrimary(resolveReferences: { maxDepth: 10 })
          _rawFeaturedBlogPostSecondary(resolveReferences: { maxDepth: 10 })
          metaDescription
          metaImage {
            asset {
              _id
            }
          }
        }
      }
    }
  }
`;

const BlogTemplate = ({
  data: { allSanityBlogPost, allSanityBlogPage },
  pageContext: { currentPage, numBlogPages, defaultPath },
}) => {
  const blogCategories = useBlogCategories();
  const posts = values(
    mapValues(allSanityBlogPost.edges, (post) => {
      return post.node;
    }),
  );
  const featurePrimary =
    allSanityBlogPage.edges[0].node._rawFeaturedBlogPostPrimary;
  const featureSecondary =
    allSanityBlogPage.edges[0].node._rawFeaturedBlogPostSecondary;

  const renderFeature = (feature, reverse = false) => (
    <div className="mb-30">
      <ImageTextOverlap
        data={{
          contentBox: {
            heading: feature.title,
            paragraph: feature.description,
            subheading: feature.category.title,
            link: [{ label: 'Read more', ariaLabel: `Read more about ${feature.title}`, page: { slug: feature.slug } }],
          },
          image: feature.image,
          reverse,
        }}
      />
    </div>
  );

  const renderBlogListing = () => {
    if (currentPage === 1) {
      return (
        <div>
          {featurePrimary && renderFeature(featurePrimary, true)}
          {/* First 4 blog posts */}
          <div className="mb-40">
            <BlogListing data={{ posts: posts.slice(0, 4) }} />
          </div>
          {featureSecondary && renderFeature(featureSecondary)}
          <div className="mb-12">
            {/* Last 4 blog posts */}
            <BlogListing data={{ posts: posts.slice(4, 9) }} />
          </div>
        </div>
      );
    }

    return (
      <div className="mb-12">
        <BlogListing data={{ posts }} />
      </div>
    );
  };

  return (
    <div>
      <SEO
        title={allSanityBlogPage.edges[0]?.node?.title}
        description={
          allSanityBlogPage.edges[0]?.node?.metaDescription
            ? allSanityBlogPage.edges[0].node.metaDescription
            : null
        }
        image={
          allSanityBlogPage.edges[0]?.node?.metaImage?.asset
            ? imageUrlFor(allSanityBlogPage.edges[0].node.metaImage).url()
            : null
        }
      />
      <CategoryNavigation
        heading={allSanityBlogPage.edges[0].node.title}
        defaultPath="/blog"
        categories={blogCategories.allSanityBlogCategory.edges}
      />
      {renderBlogListing()}
      {numBlogPages && numBlogPages > 0 && (
        <Pagination
          data={{
            currentPage,
            numPages: numBlogPages,
            path: defaultPath,
          }}
        />
      )}
    </div>
  );
};

BlogTemplate.propTypes = {
  data: PropTypes.shape({
    allSanityBlogPost: PropTypes.shape({
      edges: PropTypes.array,
    }),
    allSanityBlogPage: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numBlogPages: PropTypes.number,
    defaultPath: PropTypes.string,
  }),
};

BlogTemplate.defaultProps = {
  data: {
    allSanityBlogPost: {},
    allSanityBlogPage: {},
  },
  pageContext: PropTypes.shape({
    currentPage: 1,
    numBlogPages: 0,
    defaultPath: '',
  }),
};

export default BlogTemplate;
